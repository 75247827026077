import React from 'react';

import './Input.scss'

const Input = ({name, placeholder, type = "text", id, icon, children, ...rest}) => (
  <div className="input-wrapper">
    {
      children && (
        <div className="input-icon">
          {children}
        </div>
      )
    }
    <input
      className="input-field"
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      {...rest}
    />
  </div>
);

export default Input;
