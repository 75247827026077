import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import CareersHeader from "../components/Careers/CareersHeader"
import AvailableCareers from "../components/Careers/AvailableCareers"

export const query = graphql`
  query MyQuery {
    allBambooHrCareer {
      nodes {
        departmentLabel
        employmentStatusLabel
        jobOpeningName
        location {
          city
        }
        careerDetails {
          jobOpening {
            description
            minimumExperience
            datePosted
            jobOpeningShareUrl
          }
        }
      }
    }
  }
`

const Careers = ({ data }) => {
  const [activeSearch, setActiveSearch] = useState("")

  const bambooOpenings = data.allBambooHrCareer.nodes

  return (
    <Layout currentPage="careers">
      <SEO title="Careers" />
      <CareersHeader
        updateSearchFilter={newValue => setActiveSearch(newValue)}
      />
      {/* NOTE: not necessary now since this is included in the Bamboo openings */}
      {/* <SendMeYourCV /> */}
      <AvailableCareers
        activeSearch={activeSearch}
        bambooOpenings={bambooOpenings}
      />
      {/* <SendMeYourCV /> */}
      <div style={{ height: "87px" }} />
    </Layout>
  )
}

export default Careers
