import React from "react"
import Pagination from "@material-ui/lab/Pagination"

import "./Pagination.scss"

const CustomPagination = ({ activePage, rowsPerPage, count, onChangePage }) => {
  return (
    <div className="pagination-container">
      <Pagination
        siblingCount={0}
        count={Math.ceil(count / rowsPerPage)}
        rowsPerPage={rowsPerPage}
        activePage={activePage}
        onChange={(e, page) => onChangePage(page)}
      />
    </div>
  );
}

export default CustomPagination
