import React from "react"
import LearnMore from "../../shared/LearnMore"

import "./AvailableCareers.scss"

const JobOpening = ({ bambooOpening, onOpeningClick }) => {
  return (
    <div className="job-opening" onClick={onOpeningClick}>
      <div>
        <div className="job-header">
          <div className="job-title">
            {bambooOpening.departmentLabel} - {bambooOpening.jobOpeningName}
          </div>
          <div className="job-location">{bambooOpening.location.city}</div>
        </div>
        <div className="job-footer">
          <div className="create-date">
            <b>Posted on</b> {bambooOpening.careerDetails.jobOpening.datePosted}
          </div>
          <div className="more-action">
            <LearnMore text="More" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobOpening
