import React from "react"
import CustomModal from "../../shared/Modal/Modal"
import CloseIcon from "../../shared/CloseIcon"
import ButtonWrapper from "../../shared/Button"
import { CAREERS_EMAIL } from "../../../constants"

const JobOpeningModal = ({ bambooOpening, onClose }) => {
  return (
    <div className="job-modal-wrapper">
      <CustomModal isOpen={Boolean(bambooOpening)} setOpen={() => onClose()}>
        <div className="job-opening-modal">
          <CloseIcon onClose={onClose} />
          <div>
            <div className="job-header">
              <div className="job-title">
                {bambooOpening?.departmentLabel} -{" "}
                {bambooOpening?.jobOpeningName}
              </div>
              <div className="job-location">{bambooOpening?.location.city}</div>
              <div className="create-date" style={{ marginTop: 7 }}>
                <b>Posted on</b>{" "}
                {bambooOpening?.careerDetails.jobOpening.datePosted}
              </div>
            </div>

            <div
              style={{ marginTop: "40px" }}
              dangerouslySetInnerHTML={{
                __html: bambooOpening?.careerDetails.jobOpening.description,
              }}
            ></div>

            <div className="job-section apply-now">
              <ButtonWrapper
                label="Apply on BambooHR"
                theme="outline"
                onClick={() => {
                  window.open(
                    bambooOpening?.careerDetails.jobOpening.jobOpeningShareUrl,
                    "_blank"
                  )
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default JobOpeningModal
