import React, { useState } from "react"

import "./CareersHeader.scss"
import Container from "@material-ui/core/Container"
import Input from "../../shared/Input"
import ButtonWrapper from "../../shared/Button"
import SearchIcon from "./../../../images/search.svg"

const CareersHeader = ({ updateSearchFilter }) => {
  const [searchValue, setSearchValue] = useState("")

  const searchHandler = () => {
    updateSearchFilter(searchValue)
  }

  return (
    <div className="careers-page-header">
      <div className="career-white-shadow" />
      <Container>
        <div className="main-title">Grow with Us</div>
        <div className="sub-title">
          You know us. We want to know you.
        </div>
      </Container>

      <div className="search-container">
        <Container>
          <div className="search-input">
            <Input
              onKeyPress={e => {
                if (e.key === "Enter") searchHandler()
              }}
              value={searchValue}
              onChange={e => setSearchValue(e?.target?.value)}
              id="career-search"
              name="career-search"
              placeholder="SEARCH FOR JOB TITLES"
            >
              <img src={SearchIcon} alt="" />
            </Input>
          </div>
          <ButtonWrapper
            label="Search"
            theme="primary"
            onClick={searchHandler}
          />
        </Container>
      </div>
    </div>
  )
}

export default CareersHeader
