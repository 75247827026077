import React, { useEffect, useMemo, useState } from "react"
import Container from "@material-ui/core/Container"
import Pagination from "../../shared/Pagination"

import "./AvailableCareers.scss"
import JobOpening from "./JobOpening"
import { rowsPerPage } from "./constants"
import JobOpeningModal from "./JobOpeningModal"

const AvailableCareers = ({ activeSearch, bambooOpenings }) => {
  const [activePage, setActivePage] = useState(0)
  const [activeOpening, setActiveOpening] = useState(undefined)

  const [endIndex, startIndex] = useMemo(() => {
    const endIndex = rowsPerPage * (activePage + 1)
    const startIndex = endIndex - rowsPerPage
    return [endIndex, startIndex]
  }, [activePage])

  useEffect(() => {
    setActivePage(0)
  }, [activeSearch])

  const updatePage = newPage => {
    setActivePage(newPage)
  }

  const containSearch = ({
    jobOpeningName,
    departmentLabel,
    employmentStatusLabel,
    location: { city },
  }) => {
    const search = activeSearch.toLowerCase()
    return (
      jobOpeningName.toLowerCase().includes(search) ||
      departmentLabel.toLowerCase().includes(search) ||
      employmentStatusLabel.toLowerCase().includes(search) ||
      city.toLowerCase().includes(search)
    )
  }

  const filteredOpenings = bambooOpenings.filter(job => containSearch(job))
  const rowsCount = filteredOpenings.length

  return (
    <div className="available-careers">
      <Container>
        <div className="available-careers-title">Available Opportunities</div>
        <div className="openings-header">
          <div className="openings-count">{rowsCount} Openings</div>
          <div className="pagination">
            <Pagination
              activePage={activePage}
              count={rowsCount}
              rowsPerPage={rowsPerPage}
              onChangePage={activeIndex => updatePage(activeIndex - 1)}
            />
          </div>
        </div>
        <div className="open-positions">
          {filteredOpenings
            .filter((_, index) => index < endIndex && index >= startIndex)
            .map(opening => (
              <JobOpening
                bambooOpening={opening}
                onOpeningClick={() => setActiveOpening(opening)}
              />
            ))}
        </div>
        <div className="openings-footer"></div>
      </Container>
      <JobOpeningModal
        bambooOpening={activeOpening}
        onClose={() => setActiveOpening(undefined)}
      />
    </div>
  )
}

export default AvailableCareers
